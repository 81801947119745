.d8-form {
  margin-top: 30px;
}

.d8-form .buttons-container {
  margin-top: 20px;
};

.row {
  margin-bottom: 20px;
}

.teams label {
  font-weight: normal;
}

.teams input {
  padding-right: 10px;
}

#submit-button {
  opacity: 0.4;
}

#submit-button.valid {
  opacity: 1;
}

#submit-button.disabled {
  opacity: .3;
}

.is-creator-checkbox {
  position: absolute;
  right: 20px;
}

.text-right {
  text-align: right;
}
